import React, { useContext } from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"
import { Link, graphql } from "gatsby"
import clsx from "clsx"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Typography from "@material-ui/core/Typography"

import SEO from "../components/SEO"
import Breadcrumbs from "../components/Breadcrumbs"
import SectionTitle from "../components/SectionTitle"
import GeneralContact from "../components/contacts/GeneralContact"
import PriceList from "../components/prices/PriceList"
import LangContext from "../contexts/LangContext"

const useStyles = makeStyles(theme => ({
  contact: {
    position: "sticky",
    top: 90,
  },

  wideContact: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: -theme.spacing(3),
      width: "100vw",
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: -theme.spacing(2),
      width: "100vw",
    },
  },

  link: {
    color: theme.palette.common.black,

    "&:hover": {
      color: theme.palette.aresBlue.main,
    },
  },
  expanded: {
    "&$expanded": {
      margin: "0 auto",
      minHeight: 0,
    },
  },
  procedureIcon: {
    marginRight: 15,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center left",
    color: "transparent",
    width: 60,
    height: 60,
  },
  summaryExpanded: {
    marginTop: "0 !important",
    marginBottom: "0 !important",
    minHeight: "0 !important",
  },
  expandIcon: {
    color: "#fff",
  },
  panel: {
    marginBottom: 5,
  },

  summaryContent: {
    margin: 0,
    alignItems: "center",
  },
  panelHeader: {
    background: theme.palette.aresBlue.main,
    padding: "0 10px",
    margin: 0,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center right",
  },

  panelDetails: {
    padding: 0,
    marginTop: 4,
    marginBottom: 66
  },
  headingText: {
    color: "#fff",
    fontWeight: 600,
    flex: 1,
    lineHeight: 1.1,
    fontSize: '1.2rem',
    padding: 5
  },
}))

const PricesPage = ({
  data: {
    proceduresPages,
    teamMemberPages,
    pricesPage
  },
  pageContext: { locale, breadcrumbs, slug },
  location,
}) => {
  const classes = useStyles()
  const lang = useContext(LangContext)

  const schema = pricesPage.data.schema_json.text || ""
  const ogp = pricesPage.data.ogp_tags || ""

  const meta = {
    title: pricesPage.data.meta_title,
    description: pricesPage.data.meta_description,
    keywords: pricesPage.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  const proceduresPage = proceduresPages.edges.map(r => r.node).pop()
  const teamMemberPage = teamMemberPages.edges
    .map(r => r.node)
    .filter(tmp => tmp.lang === lang.locale)
    .pop()

  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />
      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />

          <br />

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <SectionTitle component="h1">
                {pricesPage.data.title}{" "}
                <span style={{ fontWeight: 700 }}>MONZA ARES</span>
              </SectionTitle>
            </Grid>


            <Grid item xs={12}>
              <Grid container
                spacing={2}>
                <Grid item xs={12} md={8}>
                  <Typography
                    component="div"
                    dangerouslySetInnerHTML={{
                      __html: pricesPage.data.description.html,
                    }}
                  />

                  {pricesPage.data.location_prices.map(({ location }) => (
                    <Accordion
                      square
                      key={location.id}
                      elevation={0}
                      className={classes.panel}
                      classes={{ expanded: classes.expanded }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="procedurePanelHeader"
                        classes={{
                          content: classes.summaryContent,
                          expanded: classes.summaryExpanded,
                          expandIcon: classes.expandIcon,
                        }}
                      >

                        <Typography className={classes.headingText}>
                          {location.document.data.title.text}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className={classes.panelDetails}>
                        <PriceList
                          proceduresPage={proceduresPage}
                          teamMemberPage={teamMemberPage}
                          priceDocument={location.document.data.prices}
                          servicePrices={location.document.data.service_prices}
                          teamMemberPrices={location.document.data.team_members}
                        />
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Grid>
                <Grid item xs={12} md={4}>
                  <div className={`${clsx("contactHolder", classes.wideContact)}`}>
                    <GeneralContact location={location} locale={locale} />
                  </div>
                </Grid>
              </Grid>
            </Grid>


          </Grid>


        </Container>
      </main>
    </>
  )
}

export default PricesPage

export const query = graphql`
  query PricesPageQuery($slug: String!, $locale: String!) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    proceduresPages: allPrismicProcedures(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          lang
          data {
            title
          }
        }
      }
    }

    teamMemberPages: allPrismicTeam {
      edges {
        node {
          uid
          lang
        }
      }
    }

    pricesPage: prismicPrices(uid: { eq: $slug }, lang: { eq: $locale }) {
      id
      data {        
        title 
        description {
          html
          raw
        }
        


        location_prices {
          location {
            document {
              ... on PrismicContactLocation {
                id
                data {
                  prices {
                    url
                  }
                  title {
                    text
                  }
                  service_prices {
                    price
                    service_name
                    service {
                      document {
                        ... on PrismicProcedure {
                          id
                          data {
                            title {
                              text
                            }
                            categories {
                              category {
                                uid
                              }
                            }
                          }
                          uid
                        }
                      }
                    }
                  }
                  team_members {
                    team_member {
                      uid
                      document {
                        ... on PrismicTeamMember {
                          id
                          data {
                            name {
                              text
                            }
                            profile_image {
                              alt
                              gatsbyImageData(layout: CONSTRAINED, width: 500)
                            }
                            service_prices {
                              price
                              service_name
                              service {
                                document {
                                  ... on PrismicProcedure {
                                    id
                                    uid
                                    data {
                                      title {
                                        text
                                      }
                                      categories {
                                        category {
                                          uid
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }


        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
    }    
  }
`

PricesPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    proceduresPages: PropTypes.object.isRequired,
    teamMemberPages: PropTypes.object.isRequired,
    pricesPage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
